import React, { Component } from 'react';
import InventoryList from './inventoryList';
// import API from '../../api/api'

class Inventory extends Component {

  render() {
    return (
    <div className='home'>
    <h3>{JSON.parse(localStorage.getItem('token')).groupname}: Inventory</h3>
    <p>User: {JSON.parse(localStorage.getItem('token')).username}</p>
      <InventoryList />
      {/* <API /> */}
    </div>
    );
  }
}

export default Inventory;