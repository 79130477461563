import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from 'react-router-dom';
import Logo from '../../logo.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const signOut = () => {
  localStorage.clear();
  window.open('/', '_self')
}

class Navbar extends Component {

  render() {
    return (
    <nav id="nav" className='navbar'>
        <img src={Logo} />
        <div className='navbar-content'>
        {/* {JSON.parse(localStorage.getItem('token')).username} */}
            <NavLink className='navbar-content-route' activeClassName='active' to='/'>Open Orders</NavLink>
            <NavLink className='navbar-content-route' activeClassName='active' to='/history'>Order History</NavLink>
            <NavLink className='navbar-content-route' activeClassName='active' to='/inventory'>Inventory</NavLink>
            {/* <FontAwesomeIcon icon={faCoffee} /> */}
            
            <NavLink style={{ fontWeight: '400', background: '#7f1e13' }} className='navbar-content-route' onClick={signOut}>Sign Out </NavLink>
        </div>
    </nav>
    );
  }
}

export default Navbar;