import React, { useEffect, useState, useRef } from 'react';
// import mapboxgl from 'mapbox-gl';
import OpenOrdersExpanded from '../home/openOrdersExpanded';
import truckMonster from './truck-icon.png';
import inventoryIcon from './inventory-icon.png';
import { renderToString } from 'react-dom/server';
import Gauge from '../../progressBar/gauge';
import geometryCoordinates from './geometry';

const mapboxgl = require('mapbox-gl');

mapboxgl.accessToken = 'pk.eyJ1Ijoicm13LW1lZ2giLCJhIjoiY2s3YW1idWY5MTVqZzNldGZiYml1MzUzbSJ9.rzAxhNwGCFoIXvpiq6XAtw';


const MapboxMap = (props) => {
    const [orderClusterId, setOrderClusterId] = useState(null);
    const [mapToggle, setMapToggle] = useState(false);
    const mapContainer = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/rmw-megh/clmz4xlq9024001r66ajgak0x',
      center: [-103.5917, 40.6699],
      zoom: 3,
    });

    map.on('load', () => {
      // Add a data source containing GeoJSON data (your polygon data).
      map.addSource('states', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          "features": geometryCoordinates,
        },
      });

      map.addLayer({
        'id': 'western',
        'type': 'fill',
        'source': 'states', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': "#8B0E04", // blue color fill
        'fill-opacity': 0.5
        },
  'filter': ['==', 'region', 'Western']
        });
        // Add a new layer to visualize the polygon.
        map.addLayer({
        'id': 'northeast',
        'type': 'fill',
        'source': 'states', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': "#7D6856", // blue color fill
        'fill-opacity': 0.5
        },
  'filter': ['==', 'region', 'Northeast']
        });
        // Add a new layer to visualize the polygon.
        map.addLayer({
        'id': 'southeast',
        'type': 'fill',
        'source': 'states', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': "#f2b21b", // blue color fill
        'fill-opacity': 0.5
        },
  'filter': ['==', 'region', 'Southeast']
        });
        // Add a new layer to visualize the polygon.
        map.addLayer({
        'id': 'midwest',
        'type': 'fill',
        'source': 'states', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': "#2A8EC9", // blue color fill
        'fill-opacity': 0.5
        },
  'filter': ['==', 'region', 'Midwest']
        });
        // Add a new layer to visualize the polygon.
        map.addLayer({
        'id': 'southwest',
        'type': 'fill',
        'source': 'states', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': "#000000", // blue color fill
        'fill-opacity': 0.5
        },
  'filter': ['==', 'region', 'Southwest']
        });
        // Add a black outline around the polygon.
        map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'states',
        'layout': {},
        'paint': {
        'line-color': '#000',
        'line-width': 1
        }
        });  

      // ... (Add your region layers)

     // Initialize an empty array for features
const features = [];

// Loop over props.orders to add points
if (props.orders) {
    setMapToggle(true);
    props.orders.forEach((order, i) => {
        var coordinate_values = order.orderdisplay.latlong.split(",");
        features.push({
          type: 'Feature',
           properties: {
            id: order.orderdisplay.orderno,
            description:  `<div class="mapbox-tooltip">
                                <div class="mapbox-tooltip-row">
                                    <h2>Percent Complete</h2>
                                    ${renderToString(<Gauge vertical={true} percentage={order.orderdisplay.percentcomplete} />)}
                                </div>
                                <div class="mapbox-tooltip-row">
                                    <h2>Order No.</h2>
                                    <p>${order.orderdisplay.orderno}</p>
                                    <h2>Reference no.</h2>
                                    <p>${order.orderdisplay.reference}</p>
                                    <h2>Last Updated</h2>
                                    <p>${order.orderdisplay.lastupdate}</p>
                                </div>
                                <div class="mapbox-tooltip-row">
                                    <h2>Bill to Name</h2>
                                    <p>${order.orderdisplay.billtoname}</p>
                                    <h2>Equipment</h2>
                                    <p>${order.orderdisplay.equipment}</p>
                                    <h2>Storage</h2>
                                    <p>${order.orderdisplay.storage}</p>
                                </div>
                                <div class="mapbox-tooltip-row">
                                    <h2>L of L</h2>
                                    <p>${order.orderdisplay.legoflegs}</p>
                                    <h2>Location</h2>
                                    <p>${order.orderdisplay.location}</p>
                                    <h2>Per Diem</h2>
                                    <p>${order.orderdisplay.perdiemlfd}</p>
                                </div>
                            </div>`
           },
           geometry: { 
            type: 'Point', 
            coordinates: [parseFloat(coordinate_values[1]), parseFloat(coordinate_values[0])] 
           }
        });
    });
    map.triggerRepaint();
}
else {
    setMapToggle(false)
}

// Create a popup, but don't add it to the map yet.
const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
    });
     
    map.on('mouseenter', 'unclustered-point', (e) => {
        console.log(e);
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer';
        
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;

        popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });
     
    map.on('mouseleave', 'unclustered-point', () => {
    map.getCanvas().style.cursor = '';
    popup.remove();
    });
  map.addSource('earthquakes', {
    type: 'geojson',
    // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
    // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
    // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
    data: {
        "type": "FeatureCollection",
        // "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": features
    },
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
});
map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    paint: {
        'circle-color': props.inventory ? '#ffffff' : '#7f1e13',
        'circle-stroke-color': 'rgba(255,255,255,.3)',
        'circle-stroke-width': 3,
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
        ]
    }
});

map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    },
    paint: {
      "text-color": props.inventory ? '#000000' : "#ffffff"
    }
});


 // Load the custom truck icon image and add it to the map
 map.loadImage((props.inventory ? inventoryIcon : truckMonster), function (error, image) {
    if (error) throw error;
    map.addImage('custom-truck-icon', image);
  
    map.addSource('unclustered-points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features, // Your point features
        },
      });
      
      // Add a layer to display the unclustered points (single points)
      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['!', ['has', 'point_count']], // Show only points without a "point_count" property
        layout: {
          'icon-image': 'custom-truck-icon', // Specify your custom truck icon
          'icon-size': props.inventory ? 0.8 : 0.1, // Adjust the size as needed
          'icon-allow-overlap': false, // Prevent icons from overlapping
        },
        paint: {
          'icon-opacity': 1.0, // Set the icon opacity
        },
      });

  
    //   map.addLayer({
    //     id: 'unclustered-point',
    //     type: 'circle',
    //     source: 'earthquakes',
    //     filter: ['!', ['has', 'point_count']],
    //     paint: {
    //         'circle-color': '#ffffff',
    //         'circle-radius': 5,
    //         'circle-stroke-width': 1,
    //         'circle-stroke-color': '#fff'
    //     }
    //   });

    // Add a click event to handle interactions with the plotted point.
    map.on('click', 'unclustered-point', (e) => {
      setOrderClusterId(e.features[0].properties.id);
      map.flyTo({center: e.features[0].geometry.coordinates, zoom:14});
    });
  });


      // Add a layer to display the plotted point.
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        paint: {
            // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': props.inventory ? '#fffff' : '#7f1e13',
            'circle-stroke-color': 'rgba(255,255,255,.3)',
            'circle-stroke-width': 3,
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
            ]
        }

      });
       // inspect a cluster on click
       map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('earthquakes').getClusterExpansionZoom(
            clusterId,
            (err, zoom) => {
                if (err) return;

                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                });
            }
        );
    });

    });
  }, [props.orders]);
    
  

  return (
    <div className='mapbox'>
        {!mapToggle && <p>Loading...</p>}
        <div ref={mapContainer}  id="map" style={{ width: '100%', height: '500px' }}></div>
        {orderClusterId ? <OpenOrdersExpanded colSpanOverwrite={9} individualId={orderClusterId} /> : null}
    </div>
  );
};

export default MapboxMap;
