import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";import BatteryGauge from 'react-battery-gauge'

import Gauge from "./gauge";

const ProgressBarTemplate = (props) => {

return ( props.progress ? <Gauge percentage={props.progress} /> : null )
    
    
}

export default ProgressBarTemplate;