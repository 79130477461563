import axios from "axios";
import React, { useState, useEffect } from "react";
import ProgressBarTemplate from "../../progressBar/progressBar";

const OpenOrdersExpanded = (props) => {
    const [result, setResult] = useState(null)
    let url = `${process.env.REACT_APP_ROADONE_API_ORDER_DETAILS_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ACCESSGROUPID=${JSON.parse(localStorage.getItem('token')).accessgroupid}&ORDERNO=` + props.individualId;
    useEffect(() => {
        // Fetch data or perform updates based on the props.individualId
    if (props.individualId) {
        // Example: Fetch data based on individualId
        axios.post(url).then(res => {
            setResult(res.data);
        })
      } else {
        // Handle the case when individualId is null or falsy
      }
    }, [props.individualId]);
    
    return (
        <td colSpan={props.colSpanOverwrite ? props.colSpanOverwrite : 11} className="open-orders-row-expansion">
            <div className="open-orders-row-expansion-row">
                <div className="open-orders-row-expansion-column">
                    <div className="open-orders-row-expansion-column-item">
                        <i className="fa-solid fa-map"></i>
                        <div className="line"></div>
                    </div>
                    <div className="open-orders-row-expansion-column-item">
                        <b>Current Leg: </b>{result?.value[0].currentleg}
                    </div>
                </div>

                <div className="open-orders-row-expansion-column">
                    <div className="open-orders-row-expansion-column-item">
                        <i className="fa-solid fa-truck"></i>
                        <div className="line"></div>
                    </div>
                    <div className="open-orders-row-expansion-column-item">
                        <b>Load Type: </b>{result?.value[0].loadtype}
                    </div>
                </div>

                <div className="open-orders-row-expansion-column">
                    <div className="open-orders-row-expansion-column-item">
                        <i className="fa-solid fa-location-pin"></i>
                        <div className="line"></div>
                    </div>
                    <div className="open-orders-row-expansion-column-item">
                        <b>Pickup: </b>{result?.value[0].pickup}
                    </div>
                </div>

                <div className="open-orders-row-expansion-column">
                    <div className="open-orders-row-expansion-column-item">
                        <i className="fa-solid fa-location-pin"></i>
                        <div className="line"></div>
                    </div>
                    <div className="open-orders-row-expansion-column-item">
                        <b>Delivery: </b>{result?.value[0].delivery}
                    </div>
                </div>
            </div>
            
            <div className="open-orders-row-expansion-row">
                <div className="open-orders-row-expansion-column">
                    <p style={{ fontWeight: '600', fontSize: '14px' }}>Order Details</p>
                </div>
            </div>
            <div className="open-orders-row-expansion-row">
                <div style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'column'}}>
                    <div style={{display: 'flex'}}>
                        <p className="open-orders-row-expansion-info"><b>Total Legs: </b>{result?.value[0].totalegs}</p>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p className="open-orders-row-expansion-info"><b>Equipment: </b>{result?.value[0].equipment}</p>
                        <p className="open-orders-row-expansion-info"><b>BOL: </b>{result?.value[0].bol}</p>
                        <p className="open-orders-row-expansion-info"><b>Purchase Order: </b>{result?.value[0].purchaseorder}</p>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p className="open-orders-row-expansion-info"><b>Storage LFD: </b>{result?.value[0].storagelfd}</p>
                        <p className="open-orders-row-expansion-info"><b>LFD Days: </b>{result?.value[0].lfdays}</p>
                        <p className="open-orders-row-expansion-info"><b>Per Diem LFD: </b>{result?.value[0].perdiemlfd}</p>
                        <p className="open-orders-row-expansion-info"><b>Per Diem Days: </b>{result?.value[0].perdiemdays}</p>
                    </div>
                </div>
            </div>

            <table>
                <tr>
                    <th>Leg no.</th>
                    <th>% Complete</th>
                    <th>Load Type</th>
                    <th>Ready</th>
                    <th>At Pickup</th>
                    <th>Moving</th>
                    <th>At Delivery</th>
                    <th>Inventory</th>
                    <th>Completed</th>
                    <th>Pickup</th>
                    <th>Delivery</th>
                </tr>
                {
                    result?.value.slice(1).map((leg, i) => 
                        (
                            <tr className="expanded" key={i}>
                                <td>{leg.leg}</td>
                                <td> {leg.percentcomplete ? <ProgressBarTemplate progress={leg.percentcomplete} /> : null} </td>
                                <td>{leg.loadtype}</td>
                                <td>{leg.ready}</td>
                                <td>{leg.atpickup}</td>
                                <td>{leg.moving}</td>
                                <td>{leg.atdelivery}</td>
                                <td>{leg.inventory}</td>
                                <td>{leg.completed}</td>
                                <td>{leg.pickup}</td>
                                <td>{leg.delivery}</td>
                            </tr>
                        )
                    )
                }
            </table>
        </td>
    )
};

export default OpenOrdersExpanded;