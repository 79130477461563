import React from "react";

const gaugeWidth = 100;
const gaugeVerticalWidth = 60;
const gaugeHeight = 25;
const gaugeVerticalHeight = 110;
const gaugeContentWidth = gaugeWidth - 12;
const gaugeVerticalContentWidth = gaugeVerticalWidth - 12;
const gaugeBarsNb = 10;
const gaugeBarWidth = gaugeContentWidth / gaugeBarsNb
const gaugeVerticalBarWidth = gaugeVerticalContentWidth;
const gaugeBarMargin = 1;
const gaugeBarRadius = 0;

const styles = {
  container: {
    position: "relative",
    height: `${gaugeHeight}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  verticalContainer: {
    position: "relative",
    height: `${gaugeVerticalHeight}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  barsContainer: {
    height: `${gaugeHeight}px`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "3px",
  },
  barsVerticalContainer: {
    height: `${gaugeVerticalHeight}px`,
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    marginLeft: "3px",
  },
  barContainer: {
    height: `${gaugeHeight - 10}px`,
    paddingLeft: `${gaugeBarMargin}px`,
    paddingRight: `${gaugeBarMargin}px`
  },
  barVerticalContainer: {
    height: `${gaugeHeight - 10}px`,
    paddingTop: `${gaugeBarMargin}px`,
    paddingBottom: `${gaugeBarMargin}px`
  },
  bar: {
    width: `${gaugeBarWidth - gaugeBarMargin * 2}px`,
    height: "100%",
    backgroundColor: "#3f91d3",
    zIndex: 1
  },
  grayBar: {
    width: `${gaugeBarWidth - gaugeBarMargin * 2}px`,
    height: "100%",
    backgroundColor: 'gray',
    zIndex: 1
  },
  verticalBar: {
    width: `${gaugeVerticalBarWidth - gaugeBarMargin * 2}px`,
    height: "100%",
    backgroundColor: "#3f91d3",
    zIndex: 1
  },
  verticalGrayBar: {
    width: `${gaugeVerticalBarWidth - gaugeBarMargin * 2}px`,
    height: "100%",
    backgroundColor: 'gray',
    zIndex: 1
  },
  barFirst: {
    borderTopLeftRadius: `${gaugeBarRadius}px`,
    borderBottomLeftRadius: `${gaugeBarRadius}px`
  },
  barLast: {
    borderTopRightRadius: `${gaugeBarRadius}px`,
    borderBottomRightRadius: `${gaugeBarRadius}px`
  },
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 0
  },
  p: {
    alignSelf: 'center'
  },
  h1: {
    fontSize: '14px',
    marginTop: 0
  }
};

const Gauge = (props) => {
  const percentage = props.percentage;
  const percent10 = Math.round(percentage / gaugeBarsNb);
  const percentageArray = percent10 ? [...Array(percent10).keys()] : [];
  let grayBarsCount = 10 - percentageArray.length;
  let grayBars = [];

  for (let i = 0; i < grayBarsCount; i++) {
    grayBars.push(i);
  }


  return (
    <>
      {
        props.vertical &&
        <h1 style={{ ...styles.h1 }}>
        {percentage}%
        </h1>
      }
      <div style={props.vertical ? styles.verticalContainer : styles.container }>
        <div style={props.vertical ? styles.barsVerticalContainer : styles.barsContainer}>
          {percentageArray.map((ele, index) => (
            <div style={props.vertical ? styles.barVerticalContainer : styles.barContainer}>
              {index === 0 ? (
                <div
                  key={index}
                  style={props.vertical ? { ...styles.verticalBar, ...styles.barFirst } : { ...styles.bar, ...styles.barFirst }}
                />
              ) : index === gaugeBarsNb - 1 ? (
                <div key={index} style={props.vertical ? { ...styles.verticalBar, ...styles.barLast } : { ...styles.bar, ...styles.barLast }} />
              ) : (
                <div key={index} style={ props.vertical ? {...styles.verticalBar} : { ...styles.bar }} />
              )}
            </div>
          ))
          }
          {
            grayBars.map(() => (
              <div style={props.vertical ? styles.barVerticalContainer : styles.barContainer}>
                <div style={props.vertical ? { ...styles.verticalGrayBar,  ...styles.barLast, ...styles.barFirst } : { ...styles.grayBar, ...styles.barLast, ...styles.barFirst }} />
              </div>
            ))
          }
        </div>
        {
          !props.vertical &&
          <p style={{ ...styles.p }}>
          {percentage}%
          </p>
        }
      </div>
    </>
  );
};

export default Gauge;
