import React, { Component } from 'react';
import OpenOrdersHistory from './openOrdersHistory';

class OrderHistory extends Component {
  render() {
    return (
    <div className='home'>
      <h3>{JSON.parse(localStorage.getItem('token')).groupname}: Order History</h3>
      <p>User: {JSON.parse(localStorage.getItem('token')).username}</p>
      <OpenOrdersHistory />
    </div>
    );
  }
}

export default OrderHistory;