import React, { useEffect, useState } from 'react';
import Navbar from './components/navbar/navbar';
import Home from './components/pages/home/home';
import Inventory from './components/pages/home/inventory';
import OrderHistory from './components/pages/home/orderHistory';
import './App.scss';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/pages/login/login';
import ForgotPassword from './components/pages/login/forgotPassword';

function App() {
  const [logoutTimeout, setLogoutTimeout] = useState(null);

  const handleActivity = () => {
    clearTimeout(logoutTimeout);
    setLogoutTimeout(setTimeout(handleLogout, 30 * 60 * 1000)); // 30 minutes in milliseconds
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    // Add any other necessary cleanup

    // Redirect to the login page
    window.location.href = '/login';
  };

  useEffect(() => {
    setLogoutTimeout(setTimeout(handleLogout, 30 * 60 * 10000)); // 30 minutes in milliseconds

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Clear the timeout and remove event listeners when the component unmounts
    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);

  return (
    <div className="roadOne">
      <Router>
        {localStorage.getItem('token') ? <Navbar /> : null}
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            exact
            path="/"
            element={
              localStorage.getItem('token') ? <Home /> : <Navigate to="/login" />
            }
          ></Route>
          <Route
            exact
            path="/inventory"
            element={
              localStorage.getItem('token') ? (
                <Inventory />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            exact
            path="/history"
            element={
              localStorage.getItem('token') ? (
                <OrderHistory />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
