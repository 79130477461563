import axios from "axios";
import OpenOrdersExpanded from "./openOrdersExpanded";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDoubleDown, faRefresh  } from '@fortawesome/free-solid-svg-icons'
import ProgressBarTemplate from "../../progressBar/progressBar";
import { useNavigate } from "react-router-dom";
import MapboxMap from "../mapBox/mapBox";
import "react-datepicker/dist/react-datepicker.css";

const OpenOrders = () => {

const navigate = useNavigate();

const tableToCSV = () => {
    var csv_data = [];
    var rows = document.getElementsByTagName('tr');
    for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll('td,th');
        var csvrow = [];
        for (var j = 0; j < cols.length; j++) {
            csvrow.push(cols[j].textContent);
        }
        csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
    downloadCSVFile(csv_data);
}

const downloadCSVFile = (data) => {
    var CSVFile = new Blob([data], {
        type: "text/csv"
    });
    var temp_link = document.createElement('a');
    temp_link.download = "GfG.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
}


    const [result, setResult] = useState(null);
    let orderRowsExpand = []; 
    const [orderRows, setState] = useState(null);
    const [orderRowsFinal, setFinalState] = useState(orderRows);
    const [noResults, setNoResults] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [resultsFiltered, setResultsFiltered] = useState(null);
    let url = `${process.env.REACT_APP_ROADONE_API_INVENTORY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ACCESSGROUPID=${JSON.parse(localStorage.getItem('token')).accessgroupid}&EQUIPMENT=&ORDERNO=0&REFERENCE=`;
    
    

    useEffect(() => {
        axios.post(url).then(res => {
            setResult(res.data);
            const expandedOrders = res.data.value
              .filter(order => order.percentcomplete !== "100")
              .map(order => ({ orderdisplay: order, id: order.orderno, display: false }));
            setState(expandedOrders);
            setFinalState(expandedOrders);
          });
      }, []);

  if (result) {
    result.value.forEach(order => {
        if (order.percentcomplete !== "100") {
            orderRowsExpand.push({orderdisplay: order, id: order.orderno, display: false});
        }
    })
  }


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [mapViewToggle, setMapViewToggle] = useState(false);

  const toggleOrder = (id, value) => {
    if (value) {
        result.value.forEach(order => {
            orderRowsExpand.push({orderdisplay: order, id: order.orderno, display: false});
        });
        setState(orderRowsExpand);
        setFinalState(orderRowsExpand);
        if (resultsFiltered) {
            setResultsFiltered(null);
        }
    }
    else if (resultsFiltered) {
        const filteredOrders = orderRows.map(order => {
            if (order.id === id) {
                return { ...order, display: true };
            }
        });
        setState(filteredOrders);
        setFinalState(filteredOrders);
        return;
    } else {
        const updatedOrders = orderRowsExpand.map(order => {
            if (order.id === id) {
                return { ...order, display: !order.display };
            }
            return order;
        });
        setState(updatedOrders);
        setFinalState(orderRows);
    }
};

  const filterById = async () => {
      let searchId = document.getElementById('search').value;
      let searchUrl = `${process.env.REACT_APP_ROADONE_API_INVENTORY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ACCESSGROUPID=${JSON.parse(localStorage.getItem('token')).accessgroupid}&EQUIPMENT=${searchId}&ORDERNO=0&REFERENCE=`;
      let referenceSearchUrl = `${process.env.REACT_APP_ROADONE_API_INVENTORY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ACCESSGROUPID=${JSON.parse(localStorage.getItem('token')).accessgroupid}&EQUIPMENT=&ORDERNO=0&REFERENCE=${searchId}`;
      setFinalState(null);

      var e = document.getElementById("search-type");
      var typeText = e.options[e.selectedIndex].text;

      if (typeText == 'Order Number') {
            const filteredOrders = orderRowsExpand.filter(order => (order.id+'').indexOf(searchId) > -1);
              
            if (filteredOrders.length > 0) {
                setState(filteredOrders);
                setFinalState(filteredOrders);
                setResultsFiltered(filteredOrders);
                return;
            }

            else {
                setNoResults(true);
                setTimeout(() => {
                    navigate(0);
                }, 3000);
                return;
            }
      }

      else if (typeText == 'Equipment Number') {
        orderRowsExpand = [];
      
        await axios.post(searchUrl).then(res => {
          setResult(res.data);
          res.data.value.forEach(order => {
             orderRowsExpand.push({orderdisplay: order, id: order.orderno, display: false});
  
          })
          setResultsFiltered(orderRowsExpand);
          setState(orderRowsExpand);
          setFinalState(orderRowsExpand);
          }).catch(
              function (error) {
                return Promise.reject(error)
              }
            );
          if (orderRowsExpand.length > 0) {
              return;
          } 
          else {
              setNoResults(true);
              setTimeout(() => {
                  
                navigate(0);
              }, 2000);
              return;
          }
    }
    else if (typeText === 'Reference Number') {
      orderRowsExpand = [];
    
      await axios.post(referenceSearchUrl).then(res => {
        setResult(res.data);
        res.data.value.forEach(order => {
           orderRowsExpand.push({orderdisplay: order, id: order.orderno, display: false});

        })
        setResultsFiltered(orderRowsExpand);
        setState(orderRowsExpand);
        setFinalState(orderRowsExpand);
        }).catch(
            function (error) {
              return Promise.reject(error)
            }
          );
        if (orderRowsExpand.length > 0) {
            return;
        } 
        else {
            setNoResults(true);
            setTimeout(() => {
                navigate(0);
            }, 2000);
            return;
        }
  }

  }
    const filterByStartDate = (date) => {
        setStartDate(date);
        const filteredOrders = orderRowsExpand.filter(order => Date.parse(order.orderdisplay.lastupdate) >= Date.parse(startDate.toLocaleDateString()));
        setState(filteredOrders);
        setFinalState(filteredOrders);
    }

    const filterByEndDate = (date) => {
        setEndDate(date);
        const filteredOrders = orderRowsExpand.filter(order => Date.parse(order.orderdisplay.lastupdate) <= Date.parse(endDate.toLocaleDateString()));
        setState(filteredOrders);
        setFinalState(filteredOrders);
    }

    const totalPages = Math.ceil(orderRowsFinal?.length / itemsPerPage);

    useState(() => {
        if (result) {
            setState(orderRowsExpand);
            setFinalState(orderRows);
        }
    }, [])
    

  return (
    <>
    <div className='home-date-wrapper'>
        
        <div className='home-date-wrapper-cell'>
          <p>Search</p>
          <div className="search">
            <input placeholder="search:" id="search" />
            <select name="search-type" id="search-type">
                <option>Equipment Number</option>
                <option>Reference Number</option>
                <option>Order Number</option>
            </select>
            <button onClick={filterById} placeholder="order or equiptment number:" name="search">Search</button>
         </div>
        </div>

        <div className='home-date-wrapper-cell'>
          <p>Date Start</p>
          <DatePicker selected={startDate} onChange={filterByStartDate} />
        </div>

        <div className='home-date-wrapper-cell'>
          <p>Date End</p>
          <DatePicker selected={endDate} onChange={filterByEndDate} />
        </div>

      </div>
      <div style={{display: 'flex', flexFlow: 'row-reverse'}}>
        <button className="open-orders-csv-export" onClick={tableToCSV}>Export to CSV</button>
        {/* <button className="open-orders-csv-export"><FontAwesomeIcon icon={faRefresh} /></button> */}
       { (orderRowsFinal && !mapViewToggle) ? <button className="open-orders-map-button" onClick={() => setMapViewToggle(true)}>Map View</button> : null}
       { orderRowsFinal && mapViewToggle ? <button className="open-orders-map-button" onClick={() => setMapViewToggle(false)}>List View</button> : null}

      </div>
        {(orderRowsFinal && mapViewToggle) ? <MapboxMap orders={orderRowsFinal} /> : (mapViewToggle && <p>loading...</p>)}
    { !mapViewToggle && 
    <div className="open-orders">
        <table>
        <thead>
            <th></th>
            <th>Order Number</th>
            <th>Bill to name</th>
            <th>L of L</th>
            <th>Percent Complete</th>
            <th>Reference</th>
            <th>Equipment</th>
            <th>Location</th>
            <th>Last Updated</th>
            <th>Storage</th>
            <th>Per Diem</th>
        </thead>
        { noResults ? <p style={{ color: 'red' }}>No Results from search, reloading data..</p> : null }
        {orderRowsFinal ? orderRowsFinal.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map((order, index) => (
            <> 
                <tr
                    className="open-orders-row"
                    onClick={() => toggleOrder(order.id, order.display)}
                    key={index}
                >
                    <td style={{width: '15px'}}><FontAwesomeIcon icon={ order?.display ? faAngleDoubleDown : faAngleRight} /></td>
                    <td style={{ background: order?.display ? '#2A8EC9' : '#7f1e13', color: 'white' }}>{order?.orderdisplay.orderno}</td>
                    <td>{order?.orderdisplay.billtoname}</td>
                    <td>{order?.orderdisplay.legoflegs}</td>
                    <td><ProgressBarTemplate progress={order?.orderdisplay.percentcomplete} /></td>
                    <td>{order?.orderdisplay.reference}</td>
                    <td>{order?.orderdisplay.equipment}</td>
                    <td>{order?.orderdisplay.location}</td>
                    <td>{order?.orderdisplay.lastupdate}</td>
                    <td>{order?.orderdisplay.storagelfd}</td>
                    <td>{order?.orderdisplay.perdiemlfd}</td>
                </tr>
                { 
                    order?.display && <OpenOrdersExpanded individualId={order.id} />
                }
            </>
        )) : <p>Loading...</p>}
        </table>
        <div className="open-orders-pagination-wrapper">
            <p>Rows per page</p>
            <select onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                <option display="10">10</option>
                <option display="10">50</option>
                <option display="10">100</option>
            </select>
            <p>1-10</p>
        </div>
            <p>{(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, orderRowsFinal?.length)}</p>
            <p>Total Items: {orderRowsExpand.length}</p>
        <div className="search">
            <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
        
    </div>}
    </>
  );
            
};

export default OpenOrders;